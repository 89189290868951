import { IS_PROD } from "@/lib/global_imports";
const FORCE_PROD = false;
const WORKER_PROD = "https://chat-cf.taalia.com";
const WORKER_DEV = "http://localhost:8789";

export async function postMessage(message, queryParams, waitForResponse = true) {
  try {
    console.log("Posting message:", message);

    // Construct the URL with query parameters
    const url = buildWorkerUrl(queryParams);

    // Create the body of the request
    const body = JSON.stringify(message ? { message } : {});

    // Create the request object
    const request = {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // Ensure cookies are included in the request
    };

    if (waitForResponse) {
      // Send the request to the server
      const response = await fetch(url, request);

      // Convert the response to JSON
      const data = await response.json();
      console.log("Chat Server Response:", data);

      // Return the response
      return data;
    } else {
      fetch(url, request);
    }
  } catch (error) {
    console.error(error);
    return "Error generating response from the server.";
  }
}

function buildWorkerUrl(queryParams) {
  // Determine the worker URL based on the environment
  const workerUrl = FORCE_PROD || IS_PROD ? WORKER_PROD : WORKER_DEV;
  console.log("Chat Worker URL:", workerUrl);

  // Construct the URL
  const url = new URL(workerUrl);

  // Add query parameters to the URL
  if (queryParams) {
    Object.keys(queryParams).forEach((key) =>
      url.searchParams.append(key, queryParams[key])
    );
  }

  // Return the constructed URL
  return url;
}

// export async function getWelcome() {
//   try {
//     // Construct the URL with query parameters
//     const url = buildWorkerUrl();

//     // Send the message to the server via the API route
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         // "Session-Id": sessionId,
//       },
//       credentials: "include", // Ensure cookies are included in the request
//     });

//     // Convert the response to JSON
//     const data = await response.json();
//     console.log("Chat Server Response:", data);

//     // Return the response
//     return data;
//   } catch (error) {
//     console.error(error);
//     return "Error generating response from the server.";
//   }
// }
