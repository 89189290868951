import { Router } from "preact-router";
import ChatInterface from "@/components/ChatInterface";
import StripeCheckoutHandler from "@/components/StripeCheckoutHandler";
import ClearStorage from "@/components/ClearStorage";

const App = () => {
  return (
    <Router>
      <ChatInterface path="/" />
      <StripeCheckoutHandler path="/stripe-checkout-return" />
      <ClearStorage path="/logout" />
    </Router>
  );
};

export default App;
