import { Card, CardContent, CardFooter } from "@/components/ui/card";

export default function SkuTile({ replyOption, onButtonClick }) {
  const getBorderColor = (replyOption) => {
    if (replyOption.extendedProperties.tags) {
      if (replyOption.extendedProperties.tags[0] === "popular") {
        return "border-green-500";
      } else if (replyOption.extendedProperties.tags[0] === "best_value") {
        return "border-blue-500";
      }
    }
    return "border-transparent";
  };

  const getPromoStyles = (replyOption) => {
    if (replyOption.extendedProperties.tags) {
      if (replyOption.extendedProperties.tags[0] === "popular") {
        return "bg-green-500 text-white";
      } else if (replyOption.extendedProperties.tags[0] === "best_value") {
        return "bg-blue-500 text-white";
      }
    }
    return "";
  };

  return (
    <div onClick={() => onButtonClick(replyOption)}>
      <Card
        className={`bg-amber-400 sku-tile-foreground ${getBorderColor(
          replyOption
        )} border-2 overflow-hidden sku-tile-card`}
      >
        <CardContent className="p-3 sku-tile-card-content">
          <div className="text-[1.5em] font-bold">
            {replyOption.extendedProperties.localized_time_interval ??
              replyOption.extendedProperties.unit_count ??
              "N/A"}
          </div>
          <div className="text-[1.15em] mb-2">
            {replyOption.extendedProperties.noun ??
              replyOption.extendedProperties.localized_tags[1] ??
              "N/A"}
          </div>
          <hr className="w-full border-t sku-tile-foreground mb-2" />
          <div className="text-[1em] font-bold mb-1">
            {replyOption.extendedProperties.localized_price_string ?? "N/A"}
          </div>
          <div className="text-[0.75em]">
            {replyOption.extendedProperties.localized_billing_type ?? "N/A"}
          </div>
        </CardContent>
        {replyOption.extendedProperties.tags && (
          <CardFooter
            className={`py-1 px-2 text-center text-sm font-bold ${getPromoStyles(
              replyOption
            )}`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {replyOption.extendedProperties.localized_tags[0]}
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
