import { Button } from "@/components/ui/button";

export default function ButtonRow({ replyOptions, onButtonClick }) {
  const handleButtonClick = (replyOption) => {
    console.log(`Button clicked with selectionIntent: ${replyOption.selectionIntent}`);
    if (onButtonClick) {
      onButtonClick(replyOption);
    }
  };

  return (
    <div className="w-full flex flex-wrap items-center justify-end gap-2">
      {replyOptions.map((replyOption) => (
        <Button
          key={replyOption.selectionIntent}
          variant="outline"
          className="h-9 px-3 text-sm whitespace-nowrap"
          onClick={() => handleButtonClick(replyOption)}
        >
          {replyOption.title}
        </Button>
      ))}
    </div>
  );
}
