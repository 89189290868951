export function getQueryParamsFromUrl() {
  const queryParams = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  //   console.log("queryParams", queryParams);
  return queryParams;
}

export function clearSessionStorage() {
  sessionStorage.clear();
}

export function clearCookies() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
}
