import { clearSessionStorage, clearCookies } from "@/lib/web";

export default function ClearStorage() {
  clearSessionStorage();
  clearCookies();
  setTimeout(() => {
    window.location.href = "/";
  }, 1500);
  return <div>Storage cleared</div>;
}
