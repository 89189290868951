import MessageList from "@/components/MessageList";
import MessageInput from "@/components/MessageInput";
import EmailInput from "@/components/EmailInput";
import ButtonRow from "@/components/ButtonRow";
import SkuTileGrid from "@/components/SkuTileGrid";
import Logo from "@/components/Logo";
import AppStoreTiles from "@/components/AppStoreTiles";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { postMessage } from "@/api/chat";
import { useFixedStyleWithIOsKeyboard } from "react-ios-keyboard-viewport";
import { FooterContentType } from "@/lib/enums";
import { useState, useRef, useEffect, useMemo } from "preact/hooks";
import { getQueryParamsFromUrl } from "@/lib/web";

export default function ChatInterface() {
  const [messages, setMessages] = useSessionStorage("messages", []);
  const [currentReplyOptions, setCurrentReplyOptions] = useSessionStorage(
    "currentReplyOptions",
    []
  );
  const [loading, setLoading] = useState(true);
  const [viewportHeight, setViewportHeight] = useState(
    window.visualViewport?.height || window.innerHeight
  );
  const [viewportWidth, setViewportWidth] = useState(
    window.visualViewport?.width || window.innerWidth
  );
  const [footerContentType, setFooterContentType] = useState(
    FooterContentType.MESSAGE_INPUT
  );

  // Memoize queryParams to avoid unnecessary re-renders
  const queryParams = useMemo(() => getQueryParamsFromUrl(), []);

  const messagesEndRef = useRef(null);

  const { fixedTop, fixedBottom } = useFixedStyleWithIOsKeyboard();

  // Handle viewport changes, especially when the keyboard opens
  useEffect(() => {
    const handleViewportChange = () => {
      setViewportHeight(window.visualViewport?.height || window.innerHeight);
      setViewportWidth(window.visualViewport?.width || window.innerWidth);
    };

    window.visualViewport?.addEventListener("resize", handleViewportChange);

    return () => {
      window.visualViewport?.removeEventListener("resize", handleViewportChange);
    };
  }, []);

  // Prevent body scroll when keyboard opens
  useEffect(() => {
    const lockScroll = () => {
      const scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
    };

    const unlockScroll = () => {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };

    window.addEventListener("focusin", lockScroll); // Lock scroll when input is focused (keyboard opens)
    window.addEventListener("focusout", unlockScroll); // Unlock scroll when keyboard closes

    return () => {
      window.removeEventListener("focusin", lockScroll);
      window.removeEventListener("focusout", unlockScroll);
    };
  }, []);

  const processNewServerMessages = (newServerMessages) => {
    if (newServerMessages.length > 0) {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.concat(newServerMessages);

        // Handle input type
        const lastMessage = updatedMessages[updatedMessages.length - 1];

        if (lastMessage.inputType && lastMessage.inputType === "checkout_session") {
          window.location.href = lastMessage.checkoutSessionUrl;
        } else {
          setFooterForLastMessage(lastMessage);
        }
        return updatedMessages;
      });
    }
  };

  // Set the footer content based on the last message
  const setFooterForLastMessage = (lastMessage) => {
    switch (lastMessage.inputType) {
      case "email":
        setFooterContentType(FooterContentType.EMAIL_INPUT);
        break;
      case "app_store_tiles":
        setCurrentReplyOptions(lastMessage.replyOptions);
        setFooterContentType(FooterContentType.APP_STORE_TILES);
        break;
      case "sku_options":
        setFooterContentType(FooterContentType.SKU_TILES);
        break;
      case "single_select":
      case "multi_select":
        setCurrentReplyOptions(lastMessage.replyOptions);
        setFooterContentType(FooterContentType.BUTTON_ROW);
        break;
      default:
        setFooterContentType(FooterContentType.MESSAGE_INPUT);
        setCurrentReplyOptions([]);
    }
  };

  const processNewUserMessage = (newUserMessage) => {
    setMessages((prevMessages) => [...prevMessages, newUserMessage]);
    setFooterContentType(FooterContentType.MESSAGE_INPUT);
    setCurrentReplyOptions([]);
  };

  // Fetch initial message when component mounts
  useEffect(() => {
    const fetchInitialMessage = async () => {
      try {
        // Get the last message rendered ixid
        const lastMessageRenderedId = messages[messages.length - 1]?.id ?? null;

        // Build the message
        let message = {
          sender: "user",
          lastMessageRenderedId,
          ixid: {
            messageId: "init",
          },
          timestamp: new Date(),
        };

        // If the user is returning from a payment, we need to send a message to the server to let it know
        if (queryParams.payment_return_success) {
          message.ixid = {
            messageId: `payment`,
            success: queryParams.payment_return_success === "true",
          };
        }
        const systemResponse = await postMessage(message, queryParams);
        processNewServerMessages(systemResponse);
      } catch (error) {
        console.error("Error fetching initial message:", error);
        processNewServerMessages([{ utterance: "Error loading initial message." }]);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialMessage();
  }, [queryParams]);

  const handleTextInput = async (utterance) => {
    try {
      const message = {
        sender: "user",
        utterance,
      };

      // Add the user's message to the messages
      processNewUserMessage(message);

      // Send the message to the server
      const systemResponse = await postMessage(message, queryParams);

      // Update the state with the server's response
      processNewServerMessages(systemResponse);
    } catch (error) {
      console.error(error);
      processNewServerMessages({
        utterance: "Error generating response from the server.",
      });
    }
  };

  const handleButtonClick = async (replyOption) => {
    // Create the message to send to the server
    const message = {
      sender: "user",
      utterance: replyOption.title,
      selectionIntent: replyOption.selectionIntent,
    };

    // Add the user's selection to the messages
    processNewUserMessage(message);

    // Send the message to the server
    const systemResponse = await postMessage(message, queryParams);

    // Update the state with the server's response
    processNewServerMessages(systemResponse);
  };

  // Scroll to the bottom of the messages when they change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Ensure scroll on initial load
  useEffect(() => {
    if (messages.length > 0) {
      setFooterForLastMessage(messages[messages.length - 1]);
      messagesEndRef.current?.scrollIntoView({
        behavior: "auto",
        // behavior: "smooth",
        block: "end",
      });
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  let footerContent;
  switch (footerContentType) {
    case FooterContentType.BUTTON_ROW:
      footerContent = (
        <ButtonRow replyOptions={currentReplyOptions} onButtonClick={handleButtonClick} />
      );
      break;

    case FooterContentType.MESSAGE_INPUT:
      footerContent = <MessageInput onSubmit={handleTextInput} />;
      break;

    case FooterContentType.EMAIL_INPUT:
      footerContent = <EmailInput onSubmit={handleTextInput} />;
      break;

    case FooterContentType.APP_STORE_TILES:
      footerContent = (
        <AppStoreTiles
          replyOptions={currentReplyOptions}
          onButtonClick={handleButtonClick}
        />
      );
      break;

    case FooterContentType.STRING:
      footerContent = <p>Your custom text here</p>;
      break;

    case FooterContentType.SKU_TILES:
      footerContent = (
        <SkuTileGrid
          message={messages[messages.length - 1]}
          handleButtonClick={handleButtonClick}
        />
      );
      break;

    default:
      footerContent = null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: `${scrollY}px`,
        left: 0,
        right: 0,
        height: `${viewportHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Card
        className="flex flex-col w-full min-w-[280px] max-w-[600px]"
        style={{
          height: `calc(${viewportHeight}px)`,
          width: `calc(${viewportWidth}px)`,
        }}
      >
        <CardHeader className="w-full py-3 px-4" style={fixedTop}>
          <Logo />
        </CardHeader>
        <CardContent className="flex-grow w-full min-h-0 p-0 overflow-hidden">
          <ScrollArea className="w-full h-full px-4">
            <MessageList messages={messages} />
            <div ref={messagesEndRef} />
          </ScrollArea>
        </CardContent>
        <CardFooter className="w-full p-4" style={{ ...fixedBottom, height: "auto" }}>
          {footerContent}
        </CardFooter>
      </Card>
    </div>
  );
}

function useSessionStorage(key, initialValue) {
  // Initialize state from sessionStorage or use the initial value
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  // Update sessionStorage whenever the value changes
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
