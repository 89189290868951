import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "preact/hooks";

export default function EmailInput({ onSubmit }) {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      const randomString = Math.random().toString(36).substring(2, 8);
      setEmail(`purnellfisher+${randomString}@gmail.com`);
    }
  }, []);

  const handleSend = (e) => {
    e.preventDefault();
    if (isValid && email.trim()) {
      console.log("handleSend", email);
      onSubmit(email);
      setEmail(""); // Clear the input after submitting
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)); // Basic email format validation
  };

  return (
    <form onSubmit={handleSend} className="flex w-full space-x-2">
      <Input
        type="email"
        value={email}
        onChange={handleChange}
        placeholder="Enter your email..."
        className={`flex-1 text-lg ${isValid ? "" : "border-red-500"}`}
      />
      <Button
        type="submit"
        variant="default"
        onMouseDown={handleSend}
        onTouchStart={handleSend}
        disabled={!isValid || !email.trim()} // Disable if invalid or empty
      >
        Submit
      </Button>
    </form>
  );
}
