export default function Logo() {
  return (
    <picture>
      <source
        srcSet="
        /assets/branding/taalia-wordmark-no-margin-120w.webp 1x,
        /assets/branding/taalia-wordmark-no-margin-120w@2x.webp 2x,
        /assets/branding/taalia-wordmark-no-margin-120w@3x.webp 3x,
        /assets/branding/taalia-wordmark-no-margin-120w@4x.webp 4x"
        type="image/webp"
      />
      <img
        src="/assets/branding/taalia-wordmark-no-margin-120w.png"
        srcSet="
        /assets/branding/taalia-wordmark-no-margin-120w.png 1x,
        /assets/branding/taalia-wordmark-no-margin-120w@2x.png 2x,
        /assets/branding/taalia-wordmark-no-margin-120w@3x.png 3x,
        /assets/branding/taalia-wordmark-no-margin-120w@4x.png 4x"
        alt="Taalia Logo"
        className="mx-auto"
        width="120"
        height="36"
        fetchPriority="high"
        decoding="async"
        loading="eager"
      />
    </picture>
  );
}
